.Dashboard {
  display: flex;
  text-align: center;

  .logout {
    background-color: $veryDarkGrey;
    margin-bottom: $basicDistance;
  }
  .userInfos {
    font-size: large;
    line-height: 30px;
  }
}
