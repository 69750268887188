.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
h3 {
  margin-bottom: 5%;
}
.inputs > div {
  font-size: 0.9em;

  border-bottom: black solid 1px;
  padding-bottom: 40px;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  .TextInput {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
