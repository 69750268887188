.PreviewEdited {
  border-radius: $basicRadius;
  padding: $baseContainerPadding;
  background-color: $veryDarkGrey;
  max-width: 60%;
  min-width: 600px;
  margin: auto auto;
  margin-bottom: 3%;
  h1 {
    color: white;
    margin-bottom: 3%;
    padding: 2%;
    text-align: center;
    border-bottom: white solid 1px;
  }
  * {
    color: white;
  }
}
