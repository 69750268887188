.table {
  margin: auto auto;
  .tableHeader {
    font-weight: bold;
    * {
      color: $lightBlue;
      font-size: $h3FontSize;
    }
  }
  ul {
    display: grid;
    padding: 0.5% 1.5%;
    border-radius: $basicRadius;
    align-items: center;
  }

  .row {
    margin-bottom: $basicMargin-bottom;
    border-radius: $basicRadius;
    background-color: $lightGrayColor;
  }
}
