.HtmlContent {
  h1 {
    margin: 5%;
  }
  pre {
    padding: 5%;
    width: 80%;
    background-color: lightgray;
    margin: auto auto;
  }
}
