.DividerLine {
  padding: 1px 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.11248249299719892) 0%,
    rgba(41, 41, 41, 0.31416316526610644) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  margin: 40px auto;
}
.s {
  margin: 20px auto;
}
.l {
  margin: 55px auto;
}
.x {
  margin: 70px auto;
}

.xl {
  margin: 85px auto;
}
