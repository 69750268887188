.inputButt {
  display: flex;
  align-items: center;
}
.customCronInput {
  width: 100%;
  font-size: medium;
}
.items {
  display: flex;
  margin: 20px auto;
  justify-content: center;
  .item {
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 3% auto;
    font-size: 20px;
    span {
      padding-top: 5px;
      padding-bottom: 5px;
      &:first-child {
        width: 200px;
        margin-right: 10px;
        border: 2px solid white;
        border-radius: 20px;
      }
    }
  }
}
.customCron {
  display: flex;
  width: 50%;
  min-width: 300px;
  a {
    color: blue;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  input {
    margin-right: 10px;
  }
}

.gg-add {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid green;
  transform: scale(var(--ggs, 1));
  border-radius: 22px;
  padding-left: 15px;
  margin-left: 15px;
  scale: 1.3;
  &:hover {
    cursor: pointer;
  }
}
.gg-add::after,
.gg-add::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px;
  color: green;
  scale: 1.3;
}
.gg-add::after {
  width: 2px;
  height: 10px;
  top: 4px;
  left: 8px;
}

.gg-remove {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  color: red;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 22px;
  &:hover {
    cursor: pointer;
  }
}
.gg-remove::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px;
}
