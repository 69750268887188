.LoginContainer {
  margin: 10% auto;
  width: 30%;
  text-align: center;
  input * {
    background-color: $lightGrayColor;
  }
  .loginInput {
    margin-bottom: 20px;
  }
  Button {
    width: 100%;
    font-weight: bold;
    font-size: medium;
  }
}
