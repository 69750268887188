// imports
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: local('Roboto'), url(./fonts/Roboto-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  src: local('Roboto'), url(./fonts/Roboto-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: bolder;
  src: local('Roboto'), url(./fonts/Roboto-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: lighter;
  src: local('Roboto'), url(./fonts/Roboto-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  src: local('Roboto'), url(./fonts/Roboto-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}
// Roots :
// Colors :
$veryDarkGrey: #414141;
$textColor: #414141;
$midGrey: #c5c2c2;
$lightGrayColor: #eeeeee;
$sandyBrown: #fda96f;
$green: #009100;
$orange: #ff6600;
$chromeYellow: #ffaa00;
// $purple: #9341ea;
$frenchViolet: #8400d6;
$lightBlue: #417690;

// sizings
$h1FontSize: 64px;
$h2FontSize: 25px;
$h3FontSize: 20px;

$listItemSpacing: 5px;
$sidePadding: 20px 10px;
$basicRadius: 15px;
$icon: 25px;
$heightM: 35px;
$basicWidth: 50px;
$basicMargin-bottom: 15px;
$baseContainerPadding: 3% %5;
$basicDistance: 5%;

// other
$roboto: 'Roboto', serif;

.basicRadius {
  border-radius: $basicRadius;
}
