.JobPostingImports {
  ul {
    grid-template-columns: 0.15fr 0.15fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.8fr 0.2fr;
  }
  form {
    width: 70%;
    margin: auto auto;
    .filters {
      display: flex;
      justify-content: space-around;
      margin-bottom: 5%;
      align-items: baseline;
      .SearchButton {
        text-align: center;
        width: 100px;
        cursor: pointer;
        background-color: unset;
        outline: unset;
        border: unset;
      }
      .SearchIcon {
        background: radial-gradient(
          circle,
          rgba(206, 212, 218, 0.34217436974789917) 0%,
          rgba(231, 231, 231, 1) 36%,
          rgba(255, 255, 255, 0) 100%
        );
        margin-top: 45px;
      }
    }
  }
}
