* {
  box-sizing: border-box;
  font-family: $roboto;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  color: $textColor;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  word-wrap: break-word;
  white-space: initial;
}
.icon {
  img {
    width: $icon;
  }
}
.clickable {
  cursor: pointer;
}
pre {
  white-space: pre-wrap;
}
form {
  & > * {
    margin-bottom: $heightM;
  }
}
.formValidation {
  p {
    color: red;
  }
  margin: 3%;
  font-style: italic;
  text-align: center;
}
.Toastify__toast-container {
  * {
    color: $orange;
  }
}
.emptyList {
  padding: 5%;
  font-style: italic;
}
.breadcrumbs {
  background: linear-gradient(
    90deg,
    rgba(172, 172, 172, 0.11248249299719892) 0%,
    rgba(156, 156, 156, 0.31416316526610644) 22%,
    rgba(207, 207, 207, 0) 48%
  );
  p {
    color: $orange;
    display: inline;
  }
}
h1 {
  span {
    color: $lightBlue;
    font-style: italic;
  }
}
.externalImport {
  font-size: xx-small;
}

.blue {
  color: blue;
}

.margin-left-10 {
  margin-left: 10px;
}
