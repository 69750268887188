input {
  width: 100%;
  height: 35px;
  border: solid $lightGrayColor;
  border-radius: $basicRadius;
  text-align: center;
}
input:invalid {
  background-color: ivory;
  border: none;
  outline: 2px solid $orange;
  border-radius: $basicRadius;
}
textarea:invalid {
  background-color: ivory;
  border: none;
  outline: 2px solid $orange;
  border-radius: $basicRadius;
}

input[type='checkbox'] {
  height: $heightM;
  width: 20px;
}
input[type='number'] {
  width: 80px;
}

select {
  height: $heightM;
  border-radius: $basicRadius;
  border: $veryDarkGrey solid 0.2px;
  text-align: center;
  width: $heightM * 4;
}
option {
  font-size: 18px;
}
.inputStyle {
  background: #232450;
  color: rgba(255, 255, 255, 0.7);
  padding: 15px 15px;
  margin: 10px 0;
  width: 90%;
  border-radius: 3px;
  border: none;
}
.TextInput {
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  align-items: center;
  margin: 10px auto;
}
.cronInput {
  @extend .TextInput;
  grid-template-columns: 0.3fr 0.7fr;
}
.NumberInput,
.DateInput {
  @extend .TextInput;
  input {
    width: 150px;
  }
}
.CheckBoxInput {
  @extend .TextInput;
  grid-template-columns: 0.1fr 0.9fr;
  width: 70%;
}
.ObjectInput {
  width: 100%;
  line-height: $heightM;
  textarea {
    width: 100%;
    height: $heightM * 2;
    border-radius: $basicRadius;
    padding: 10px;
    border: solid 2px;
    &:focus {
      outline: none;
    }
  }
  .extraHint {
    padding-bottom: 100px;
  }
  h4 {
    text-align: left;
  }
}

.subFormSelectBox {
  label {
    display: block;
    padding: 15px;
    padding-bottom: 7px;
    color: #8400d6;
  }
  place-self: center;
  text-align: center;
}
.defaultSelectBox {
  @extend .TextInput;
}
.CronSelectBox,
.TemplateSelectBox {
  @extend .TextInput;
  select {
    width: 100%;
    font-size: medium;
  }
}

.VerticalSelectBox {
  display: flex;
  flex-direction: column;
}

.labelAbove {
  label {
    margin: 0;
    padding: 15px;
    padding-bottom: 7px;
    color: #8400d6;
  }
  select {
    outline: none;
    border: none;
  }
  button {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    color: #414141;
    height: 35px;
    border-radius: 15px;
    border: #414141 solid 0.2px;
    text-align: center;
    width: 140px;
  }
  option {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    color: #414141;
    font-size: 18px;
  }
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  place-self: center;
  text-align: center;
}
