.Templates {
  @extend .FeedsList;
  .table {
    width: 70%;
    text-align: center;
    margin: auto auto;
  }
  ul {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 50px 0;
    align-items: center;
    justify-items: center;
  }
  .clickable {
    margin-top: 20px;
  }
  .AdvancedDropDown {
    right: -200px;
    top: 30px;
  }
  a {
    color: blue;
  }
  .download {
    color: blue;
    cursor: pointer;
  }
}
.AddNewTemplate {
  .inputContainer {
    max-width: 60%;
  }
  .zipInput {
    display: grid;
    grid-template-columns: 0.3fr 0.7fr;
    input,
    input:invalid {
      width: unset;
      outline: unset;
      background-color: unset;
      border-radius: unset;
    }
    margin-top: 40px;
    .custom-file-upload {
      border: 1px solid #ccc;
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;
    }
  }
}
