.greyRoundContainer {
  background-color: $lightGrayColor;
  border-radius: $basicRadius * 2;
  padding: $baseContainerPadding;
  max-width: 70%;
  min-width: 600px;
  margin-right: auto;
  margin-left: auto;
}
.componentContainer {
  margin: auto auto;
  border-radius: $basicRadius;
  padding: $baseContainerPadding;
}
.pageContainer {
  padding: 3% 15%;
  margin: auto auto;
  & > * {
    margin-bottom: $heightM;
  }
}
.pageContainerWide {
  @extend .pageContainer;
  padding: 3%;
}
.checks {
  margin-top: 50px;
}
