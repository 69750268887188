.DeleteFeed {
  @extend .EditFeed;
  h2 {
    text-align: center;
    margin-bottom: $basicDistance;
  }
  .deleteAssurance {
    margin: 5% auto;
    .TextInput {
      width: 70%;
    }
  }
}
