.FeedPage {
  .titleAndOptions {
    display: flex;
    justify-content: center;
    align-items: baseline;
    .clickable {
      position: relative;
      color: blue;
      border-radius: 50%;
      margin-left: 10px;
      img {
        margin-bottom: -11px;
      }
    }
    .AdvancedDropDown {
      margin-top: 20px;
      margin-right: -150px;
    }
  }

  .table {
    ul {
      grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr;
    }
    li {
      text-align: center;
      padding: 0 25px;
    }
  }
  .lastImportRun {
    ul {
      grid-template-columns: 1fr 1fr 2fr 2fr 2fr;
    }
  }

  h1 {
    margin-bottom: 10%;
    margin-top: 5%;
  }
  .ChartTotalPostings {
    width: 400px;
    margin: auto auto;
  }
  .postingCharts {
    margin-top: 50px;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    .postingsChartContainer {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > div {
        margin-top: 60px;
      }
    }
  }
  .lineChartHead {
    margin-top: 8%;
  }
}
