.MappingHelper {
  p {
    text-align: center;
    font-size: large;
    border: 1px solid black;
    // display: inline-block;
    padding: 5px 10px;
    margin: auto auto;
    margin-bottom: 3%;
    width: 20%;
    border-radius: 10px;
    span:last-child {
      font-style: italic;
      color: orange;
      margin-left: 10px;
    }
  }
  h4 {
    max-width: 60%;
    margin: auto auto;
    margin-bottom: 3%;
  }
}
