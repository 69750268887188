.ReaderConfig {
  h2 {
    // text-align: left;
    margin-bottom: 20px;
  }
  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .inputs > div {
    font-size: 0.9em;

    border-bottom: black solid 1px;
    padding-bottom: 40px;
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    .TextInput {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .jobList {
    width: 50%;
    border-bottom: solid black 1px;
    margin: auto auto;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .title {
    text-align: center;
    margin-bottom: 60px;
    * {
      display: inline;
    }
    .help h3 {
      color: blue;
      padding: 20px;
    }
  }
}
.JobBoards {
  margin: 20px auto;
  .inputs {
    grid-template-columns: repeat(4, 1fr);
    .CheckBoxInput {
      padding-bottom: 0;
      label {
        margin-left: 5px;
      }
    }
    * {
      border-bottom: unset;
    }
  }
}
