.EditFeed {
  form {
    .inputContainer {
      width: 80%;
      align-items: baseline;
      margin: auto auto;
    }
    .checkBoxes {
      display: grid;
      grid-template-columns: 0.5fr 0.5fr;
      align-items: center;
    }
    .SaveButt {
      background-color: $green;
      margin-top: 0;
      padding-right: 20px;
      padding-left: 20px;
    }
    .CancelButt {
      @extend .SaveButt;
      background-color: $veryDarkGrey;
    }
    .context {
      margin: 3%;
    }
    .cron {
      .CheckBoxInput {
        // grid-template-columns: 0.1fr 0.7fr;
        margin: unset;
        display: flex;
        justify-content: center;
        width: unset;
        label {
          margin-left: 10%;
          width: 180px;
          text-align: left;
        }
      }
    }
  }
  .quotas {
    text-align: center;
    .NumberInput {
      grid-template-columns: 0.5fr 0.5fr;
    }
  }
}
.readerContainer {
  .SelectBox {
    margin-bottom: 30px;
  }
}
.useDefaults {
  text-align: center;
  button {
    width: auto;
  }
  .CheckBoxInput {
    width: 40%;
    text-align: center;
    grid-template-columns: 1fr 5fr;
  }
  margin: 40px auto;
}
