.copyToClipboard {
  span {
    position: relative;
    color: red;
    &:hover {
      cursor: pointer;
    }
    &:active {
      font-size: 15px;
    }
  }
  .copied {
    color: green;
  }
  .errorOnHover {
    position: absolute;
    background-color: rgb(238, 238, 156);
    border-radius: 20px;
    padding: 0.9%;
    z-index: 99;
  }
}
