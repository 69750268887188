.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4% 6%;
  background-color: $veryDarkGrey;
  * {
    color: $orange;
    font-weight: 500;
  }
  ul {
    display: flex;
    justify-content: space-between;
    width: 40%;
    li {
      width: 50px;
    }
  }
  border-bottom: 0.5px solid;
}
