$buttonWidth: 70px;

button {
  &:active {
    transform: translateY(4px);
  }
}
.buttonBase {
  background-color: $orange;
  padding: 7px 9px;
  display: inline-block;
  border-radius: $basicRadius;
  text-align: center;
  color: white;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
  border: none;
  min-width: 60px;
  width: $basicWidth * 2;
  min-height: $heightM;
}
.buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}
.disabled {
  background-color: $veryDarkGrey;
}
.SaveButt {
  background-color: $green;
  color: white;
  border-radius: 100%;
  width: $buttonWidth;
  height: $buttonWidth;
  display: flex;
  align-items: center;
  justify-content: center;
  &:active {
    width: 90px;
  }
}
.deleteButt {
  @extend .SaveButt;
  background-color: red !important;
}
.CancelButt {
  @extend .SaveButt;
  background-color: $veryDarkGrey;
  color: white;
  width: unset;
}
button:disabled {
  background-color: $lightGrayColor !important;
  color: $veryDarkGrey;
}
.BackButton {
  position: fixed;
  right: $basicDistance;
  bottom: $basicDistance;
  background-color: unset;
  border: none;
  img {
    width: $buttonWidth;
    &:hover {
      cursor: pointer;
    }
    &:active {
      width: 90px;
    }
  }
}
