.JobPostingDetails {
  ul {
    grid-template-columns: 0.2fr 0.2fr 0.2fr 0.4fr 0.4fr 0.3fr 0.3fr 0.3fr;
  }
  h1 {
    margin: 5% auto;
  }
  h2 {
    margin: 5% auto 2% auto;
  }
  .JobPostingImports {
    ul {
      grid-template-columns: 0.15fr 0.15fr 0.2fr 0.2fr 0.3fr 0.2fr 0.4fr;
    }
  }
}
