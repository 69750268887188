.Users {
  display: flex;
  justify-content: space-around;
  & > div {
    width: 100%;
    background-color: $lightGrayColor;
    padding: 1.5%;
    border-radius: $basicRadius;
    margin: 3%;
    height: fit-content;
  }
  .createNewUserButton {
    width: 100%;
  }

  .SingleUser {
    margin: 3%;
    display: flex;
    justify-content: center;
    li {
      text-align: left;
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: 1px solid $lightGrayColor;
      line-height: 30px;
      &:last-child {
        border-bottom: unset;
      }
    }
    background-color: $midGrey;
    border-radius: $basicRadius;
    padding: 3%;
    &:hover {
      background-color: $lightBlue;
      li * {
        color: white;
      }
    }
  }
  ul {
    line-height: 20px;
  }
  .createUser {
    padding: 1.5% 7%;
    .inputs {
      padding: $basicDistance;
    }
    h1 {
      font-size: 30px;
      font-weight: normal;
      margin-bottom: $basicDistance;
    }
  }
  .accordion {
    .accordionTitle {
      padding-right: 20px;
      font-size: 30px;
    }
    // width: 500px;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    cursor: pointer;
    .hideShow {
      color: $lightBlue;
      font-size: 20px;
    }
  }
}
.EditUser {
  .deleteUser {
    text-align: center;
  }
  .deleteUserButton {
    width: 30%;
    background-color: red;
    font-size: large;
  }
  h1 {
    margin-bottom: $basicDistance;
  }
}
